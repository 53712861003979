
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-navy-100: #1A1D4A !default;
$emu-semantic-colors-primary-teal-100: #42B4CE !default;
$emu-semantic-colors-secondary-mint-100: #D3EFF4 !default;
$emu-semantic-colors-secondary-dark-gray-100: #B5B5B5 !default;
$emu-semantic-colors-secondary-dark-gray-200: #858585 !default;
$emu-semantic-colors-secondary-dark-gray-300: #707070 !default;
$emu-semantic-colors-secondary-light-gray-100: #F2F2F2 !default;
$emu-semantic-font-weight-325: 325 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: Arial !default;
$emu-semantic-font-families-heading: 'Collier-Book', sans-serif !default;
$emu-semantic-font-families-mono: Courier !default;
$emu-semantic-font-families-collier-bold: 'Collier-Bold', sans-serif !default;
$emu-semantic-font-families-collier-italic: 'Collier-Extra-Condensed-Italic', sans-serif !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 0px !default;
$emu-semantic-spacing-vertical: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 22px !default;
$emu-semantic-spacing-one-line-height-narrow: 17px !default;
$emu-semantic-font-sizes-narrow-medium: 16px !default;
$emu-semantic-font-sizes-narrow-large: 16.97px !default;
$emu-semantic-font-sizes-narrow-xl: 11px !default;
$emu-semantic-font-sizes-narrow-xxl: 14px !default;
$emu-semantic-font-sizes-narrow-xxxl: 32px !default;
$emu-semantic-font-sizes-wide-medium: 16px !default;
$emu-semantic-font-sizes-wide-large: 22.62px !default;
$emu-semantic-font-sizes-wide-xl: 20px !default;
$emu-semantic-font-sizes-wide-xxl: 22px !default;
$emu-semantic-font-sizes-wide-xxxl: 56px !default;
$emu-semantic-line-heights-narrow-large: 17px !default;
$emu-semantic-line-heights-narrow-medium: 17px !default;
$emu-semantic-line-heights-narrow-xl: 34px !default;
$emu-semantic-line-heights-narrow-xxl: 34px !default;
$emu-semantic-line-heights-narrow-xxxl: 51px !default;
$emu-semantic-line-heights-wide-large: 22px !default;
$emu-semantic-line-heights-wide-medium: 22px !default;
$emu-semantic-line-heights-wide-xl: 44px !default;
$emu-semantic-line-heights-wide-xxl: 44px !default;
$emu-semantic-line-heights-wide-xxxl: 66px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-tablet: 992px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1200px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-breakpoints-max: 2000px !default;
$emu-semantic-sizing-one-line-height-wide: 22px !default;
$emu-semantic-sizing-one-line-height-narrow: 17px !default;
$emu-semantic-typography-narrow-headings-xxxl: 300 32px/51px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 400 14px/34px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 400 11px/34px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 300 32px/51px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 400 16px/17px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 14px/18px Arial !default;
$emu-semantic-typography-wide-headings-xxxl: 300 56px/66px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 400 22px/44px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 400 20px/44px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-wide-headings-large: 300 56px/66px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 400 16px/22px 'Collier-Book', sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 18px/28px Arial !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'navy': (
          '100': $emu-semantic-colors-primary-navy-100
        ),
        'teal': (
          '100': $emu-semantic-colors-primary-teal-100
        )
      ),
      'secondary': (
        'mint': (
          '100': $emu-semantic-colors-secondary-mint-100
        ),
        'darkGray': (
          '100': $emu-semantic-colors-secondary-dark-gray-100,
          '200': $emu-semantic-colors-secondary-dark-gray-200,
          '300': $emu-semantic-colors-secondary-dark-gray-300
        ),
        'lightGray': (
          '100': $emu-semantic-colors-secondary-light-gray-100
        )
      )
    ),
    'fontWeight': (
      '325': $emu-semantic-font-weight-325,
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono,
      'collierBold': $emu-semantic-font-families-collier-bold,
      'collierItalic': $emu-semantic-font-families-collier-italic
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-semantic-line-heights-narrow-large,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'tablet': $emu-semantic-sizing-breakpoints-tablet,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large,
        'max': $emu-semantic-sizing-breakpoints-max
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
