@font-face {
  font-family: Collier-Bold;
  src: url("resources/fonts/Collier-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Collier-Book;
  src: url("resources/fonts/Collier-Book.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Collier-Extra-Condensed-Italic;
  src: url("resources/fonts/Collier-Extra-Condensed-Italic.woff") format("woff");
  font-display: swap;
}

body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
  color: var(--emu-semantic-colors-primary-navy-100);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  color: inherit;
  margin: var(--emu-common-spacing-none);
  line-height: normal;
}

h4 {
  letter-spacing: -1.28px;
}

@media (min-width: 1024px) {
  h4 {
    letter-spacing: -2.24px;
  }
}

h4 i {
  font-family: var(--emu-semantic-font-families-collier-light-italic);
}

p {
  color: var(--emu-semantic-colors-primary-navy-100);
  margin: var(--emu-common-spacing-none);
}

sup {
  font-size: 60%;
  line-height: 0;
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: rgba(0, 0, 0, 0);
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, .4);
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-primary-dark: var(--emu-common-colors-red-900);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-navy-100: #1a1d4a;
  --emu-semantic-colors-primary-teal-100: #42b4ce;
  --emu-semantic-colors-secondary-mint-100: #d3eff4;
  --emu-semantic-colors-secondary-dark-gray-100: #b5b5b5;
  --emu-semantic-colors-secondary-dark-gray-200: #858585;
  --emu-semantic-colors-secondary-dark-gray-300: #707070;
  --emu-semantic-colors-secondary-light-gray-100: #f2f2f2;
  --emu-semantic-font-weight-325: 325;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: Arial;
  --emu-semantic-font-families-heading: "Collier-Book", sans-serif;
  --emu-semantic-font-families-mono: var(--emu-common-font-families-mono);
  --emu-semantic-font-families-collier-bold: "Collier-Bold", sans-serif;
  --emu-semantic-font-families-collier-italic: "Collier-Extra-Condensed-Italic", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-font-sizes-narrow-medium: 16px;
  --emu-semantic-font-sizes-narrow-large: var(--emu-common-font-sizes-narrow-large);
  --emu-semantic-font-sizes-narrow-xl: 11px;
  --emu-semantic-font-sizes-narrow-xxl: 14px;
  --emu-semantic-font-sizes-narrow-xxxl: 32px;
  --emu-semantic-font-sizes-wide-medium: 16px;
  --emu-semantic-font-sizes-wide-large: var(--emu-common-font-sizes-wide-large);
  --emu-semantic-font-sizes-wide-xl: 20px;
  --emu-semantic-font-sizes-wide-xxl: 22px;
  --emu-semantic-font-sizes-wide-xxxl: 56px;
  --emu-semantic-line-heights-narrow-large: 17px;
  --emu-semantic-line-heights-narrow-medium: 17px;
  --emu-semantic-line-heights-narrow-xl: 34px;
  --emu-semantic-line-heights-narrow-xxl: 34px;
  --emu-semantic-line-heights-narrow-xxxl: 51px;
  --emu-semantic-line-heights-wide-large: 22px;
  --emu-semantic-line-heights-wide-medium: 22px;
  --emu-semantic-line-heights-wide-xl: 44px;
  --emu-semantic-line-heights-wide-xxl: 44px;
  --emu-semantic-line-heights-wide-xxxl: 66px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-breakpoints-max: 2000px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) 14px / 18px var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) 18px / 28px var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-medium);
  --emu-component-lists-accordion-item-header-icon-size-height: 12px;
  --emu-component-lists-accordion-item-header-icon-size-width: 12px;
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 rgba(0, 0, 0, .6);
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-border-radius-primary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-primary-teal-100);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-primary-teal-100);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-primary-teal-100);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-primary-teal-100);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 600;
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-large);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-large);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: 5px;
  --emu-component-containers-carousel-controls-padding-top-wide: 5px;
  --emu-component-containers-carousel-controls-padding-right-narrow: 9px;
  --emu-component-containers-carousel-controls-padding-right-wide: 9px;
  --emu-component-containers-carousel-controls-padding-bottom-narrow: 5px;
  --emu-component-containers-carousel-controls-padding-bottom-wide: 5px;
  --emu-component-containers-carousel-controls-padding-left-narrow: 9px;
  --emu-component-containers-carousel-controls-padding-left-wide: 9px;
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-medium);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 rgba(0, 0, 0, .15);
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: rgba(0, 0, 0, .75);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: rgba(0, 0, 0, .75);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 rgba(0, 0, 0, .15);
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: rgba(35, 40, 47, .6);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: rgba(35, 40, 47, .6);
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 rgba(215, 26, 18, .15);
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 rgba(215, 26, 18, .2);
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: rgba(193, 200, 209, .6);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: rgba(193, 200, 209, .6);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: rgba(215, 26, 18, .1);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: rgba(215, 26, 18, .1);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: rgba(255, 255, 255, .5);
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: rgba(255, 255, 255, .5);
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: rgba(0, 0, 0, .8);
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: rgba(0, 0, 0, .8);
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 rgba(0, 0, 0, .15);
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: rgba(0, 0, 0, .75);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: rgba(0, 0, 0, .75);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: rgba(255, 255, 255, .9);
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 rgba(0, 0, 0, .15);
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: rgba(35, 40, 47, .6);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: rgba(35, 40, 47, .6);
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 rgba(215, 26, 18, .15);
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 rgba(215, 26, 18, .2);
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: rgba(193, 200, 209, .6);
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: rgba(193, 200, 209, .6);
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: rgba(215, 26, 18, .1);
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: rgba(215, 26, 18, .1);
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: rgba(255, 255, 255, .5);
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: rgba(255, 255, 255, .5);
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: rgba(255, 255, 255, .25);
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: rgba(0, 0, 0, .8);
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: rgba(0, 0, 0, .8);
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: 700;
  --emu-component-layers-cookie-consent-banner-max-width: 1808px;
  --emu-component-layers-cookie-consent-banner-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px rgba(0, 0, 0, .2);
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-body);
  text-align: left;
  line-height: 1.4;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

body {
  max-width: var(--emu-semantic-sizing-breakpoints-max);
  margin: auto;
  position: relative;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--root {
  margin-left: auto;
  margin-right: auto;
}

.content--centered {
  text-align: center;
}

.container--primary {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .container--primary {
    margin-left: var(--emu-common-spacing-xl);
    margin-right: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.container--secondary {
  padding-top: var(--emu-common-spacing-large);
  padding-right: 25px;
  padding-bottom: var(--emu-common-spacing-large);
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 25px;
}

@media (min-width: 1024px) {
  .container--secondary {
    padding-right: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-xl);
    max-width: 928px;
    margin-left: auto;
    margin-right: auto;
  }
}

.bg--mint {
  background-color: var(--emu-semantic-colors-secondary-mint-100);
}

.bg--light-gray {
  background-color: var(--emu-semantic-colors-secondary-light-gray-100);
}

.hero-mobile-banner {
  position: relative;
}

.hero-mobile-banner img {
  width: 100%;
  height: auto;
}

.hero-mobile-banner__content {
  padding-bottom: 35px;
  padding-left: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.hero-mobile-banner__content-img {
  max-width: 213px;
}

.hero-mobile-banner__disclaimer p {
  font-size: 10px;
  font-family: var(--emu-semantic-font-families-body);
  color: var(--emu-common-colors-white);
  margin-top: var(--emu-common-spacing-large);
  line-height: normal;
}

@media (min-width: 1024px) {
  .natrelle__fas-section {
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: var(--emu-common-spacing-xl);
    max-width: 1139px;
  }
}

.natrelle__fas-section .aaaem-title {
  margin-bottom: var(--emu-common-spacing-medium);
}

.natrelle__fas-section .cmp-form {
  position: relative;
}

.natrelle__fas-section .cmp-form-text {
  border: 1.5px solid var(--emu-common-colors-white);
  padding: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .natrelle__fas-section .cmp-form-text {
    padding: 9px;
  }
}

.natrelle__fas-section .cmp-form-text__text {
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  color: var(--emu-semantic-colors-secondary-dark-gray-200);
  border: none;
  width: 100%;
  padding-left: 15px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .natrelle__fas-section .cmp-form-text__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 23px;
  }
}

.natrelle__fas-section .cmp-form .button {
  position: absolute;
}

.natrelle__fas-section .cmp-form .button .cmp-form-button {
  font-size: var(--emu-common-sizing-none);
  position: absolute;
  top: 14px;
  right: 15px;
}

@media (min-width: 1024px) {
  .natrelle__fas-section .cmp-form .button .cmp-form-button {
    top: 19px;
    right: 19px;
  }
}

.natrelle__fas-section .cmp-form .button .cmp-form-button:after {
  content: "";
  background-image: url("resources/images/fas_icon_desktop.png");
  background-size: cover;
  width: 40px;
  height: 38px;
  display: block;
  position: relative;
}

@media (min-width: 1024px) {
  .natrelle__fas-section .cmp-form .button .cmp-form-button:after {
    width: 48px;
    height: 46px;
  }
}

.natrelle__fas-section .cmp-form.form-has-error .button .cmp-form-button {
  opacity: .4;
  pointer-events: none;
}

.natrelle__fas-inner-container > .container {
  max-width: 300px;
  margin: auto;
}

@media (min-width: 1024px) {
  .natrelle__fas-inner-container > .container {
    max-width: 638px;
  }
}

.natrelle__fas-inner-container .fas-url {
  display: none;
}

.natrelle__fas-inner-container .error-message {
  color: var(--emu-common-colors-red-600);
  margin-top: var(--emu-common-spacing-small);
  margin-left: var(--emu-common-spacing-xxs);
}

.cq-Editable-dom--container .fas-url {
  display: block !important;
}

.natrelle__image-grid-container {
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 200px;
  margin-top: 147px;
  margin-bottom: 96px;
  padding-bottom: 30px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 1024px) {
  .natrelle__image-grid-container {
    grid-gap: 37px;
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 37px;
    margin-top: 283px;
    margin-bottom: 128px;
    padding-bottom: 103px;
  }
}

.natrelle__image-grid-container > .container {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media (min-width: 1024px) {
  .natrelle__image-grid-container > .container {
    -ms-grid-row-align: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: calc(50% - 80px);
    max-width: 739px;
  }
}

.natrelle__image-grid-container > .container .image-border:after {
  border: 1.5px solid var(--emu-common-colors-white);
  pointer-events: none;
  height: calc(100% - 11px);
}

@media (min-width: 1024px) {
  .natrelle__image-grid-container > .container .image-border:after {
    width: calc(100% - 21px);
  }
}

.natrelle__image-grid-container > .container:first-child {
  transform: translateY(-34px);
}

@media (min-width: 1024px) {
  .natrelle__image-grid-container > .container:first-child {
    transform: none;
  }
}

.natrelle__image-grid-container > .container:first-child .image-border:after {
  top: var(--emu-common-sizing-none);
  left: var(--emu-common-sizing-none);
  border-top: none;
  border-left: none;
}

@media (min-width: 1024px) {
  .natrelle__image-grid-container > .container:first-child .image-border:after {
    height: calc(100% - 21px);
  }
}

.natrelle__image-grid-container > .container:last-child .image-border:after {
  border-bottom: none;
  border-right: none;
  width: calc(100% - 11px);
  top: 11px;
  left: 11px;
}

@media (min-width: 1024px) {
  .natrelle__image-grid-container > .container:last-child .image-border:after {
    height: calc(100% - 25px);
    top: 25px;
    left: 21px;
  }
}

.natrelle__image-grid-inner-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 1024px) {
  .natrelle__image-grid-inner-container {
    margin: unset;
    width: 100%;
    margin-top: -118px;
  }
}

.natrelle__image-grid-inner-container .natrelle__image-disclaimer {
  pointer-events: none;
  font-size: 11px;
  bottom: 22px;
  left: 24px;
}

@media (min-width: 1024px) {
  .natrelle__image-grid-inner-container .natrelle__image-disclaimer {
    left: 55px;
    bottom: var(--emu-common-spacing-large);
  }
}

.natrelle__image-grid-image {
  max-width: 350px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .natrelle__image-grid-image {
    max-width: 560px;
  }
}

@media (min-width: 1024px) {
  .natrelle__image-grid-image {
    max-width: 100%;
  }
}

.natrelle__image-grid-image img {
  width: 100%;
  transition: all .6s ease-in-out;
}

.natrelle__image-grid-image:hover img {
  transform: scale(1.3);
}

.natrelle__image-grid-content {
  pointer-events: none;
  position: absolute;
  bottom: 50px;
  left: 24px;
}

.cq-Editable-dom--container .natrelle__image-grid-content {
  position: static;
}

@media (min-width: 1024px) {
  .natrelle__image-grid-content {
    bottom: 83px;
    left: 55px;
  }
}

.natrelle__image-grid-content .aaaem-text p, .natrelle__image-grid-content .aaaem-title {
  color: var(--emu-common-colors-white);
}

.natrelle__image-grid-content .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  letter-spacing: 1px;
  line-height: normal;
}

@media (min-width: 1200px) {
  .natrelle__image-grid-content .aaaem-text p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

.natrelle__image-grid-content .cmp-title__text {
  margin-bottom: var(--emu-common-spacing-xs);
}

@media (min-width: 1024px) {
  .natrelle__image-grid-content .cmp-title__text {
    margin-bottom: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  }
}

@media (min-width: 1200px) {
  .natrelle__image-grid-content .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxxl);
  }
}

.natrelle__image-grid-content .cmp-title__text:after {
  content: "";
  margin-left: var(--emu-common-spacing-xs);
  background-image: url("resources/images/arrow_desktop.png");
  background-size: cover;
  width: 18px;
  height: 17px;
  display: inline-block;
}

@media (min-width: 1200px) {
  .natrelle__image-grid-content .cmp-title__text:after {
    width: var(--emu-common-sizing-medium);
    height: 30px;
  }
}

.find-your-fullness {
  padding-top: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  margin-top: 96px;
  margin-bottom: 96px;
}

@media (min-width: 1024px) {
  .find-your-fullness {
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.find-your-fullness__text {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .find-your-fullness__text {
    margin-bottom: var(--emu-common-spacing-xl);
  }
}

.find-your-fullness__text h4 {
  text-align: center;
}

.find-your-fullness__text h4 br {
  display: block;
}

@media (min-width: 1024px) {
  .find-your-fullness__text h4 br {
    display: none;
  }
}

.find-your-fullness__text p {
  margin-top: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .find-your-fullness__text p {
    margin-top: var(--emu-common-spacing-large);
  }
}

.natrelle__fullness-section {
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 163px;
  padding-bottom: 33px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .natrelle__fullness-section {
    grid-gap: 8%;
    padding-right: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-large);
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8%;
    min-height: 595px;
    margin-top: 128px;
    margin-bottom: 160px;
  }
}

@media (min-width: 1440px) {
  .natrelle__fullness-section {
    grid-gap: 152px;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 152px;
    padding-left: 145px;
    padding-right: 152px;
  }
}

.natrelle__fullness-section.image-border:after {
  display: none;
}

@media (min-width: 1024px) {
  .natrelle__fullness-section.image-border:after {
    border-top: none;
    border-right: var(--emu-common-border-width-medium) solid var(--emu-common-colors-white);
    top: var(--emu-common-spacing-none);
    left: var(--emu-common-spacing-medium);
    z-index: var(--emu-common-other-z-index-base);
    width: calc(100% - 32px);
    height: calc(100% - 16px);
    display: block;
  }
}

@media (min-width: 1440px) {
  .natrelle__fullness-section.image-border:after {
    left: var(--emu-common-spacing-large);
    width: calc(100% - 64px);
    height: calc(100% - 34px);
  }
}

@media (min-width: 1024px) {
  .natrelle__fullness-content {
    margin-top: var(--emu-common-spacing-none);
    letter-spacing: .3px;
    max-width: 562px;
    padding-top: 47px;
    padding-bottom: 47px;
  }
}

.natrelle__fullness-content br {
  display: none;
}

@media (min-width: 1200px) {
  .natrelle__fullness-content br {
    display: block;
  }
}

.natrelle__fullness-content .aaaem-title {
  margin-bottom: 14px;
}

@media (min-width: 1024px) {
  .natrelle__fullness-content .aaaem-title {
    text-align: unset;
  }
}

.natrelle__fullness-img-section {
  display: -ms-grid;
  -ms-grid-columns: auto 27.5px auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  grid-gap: 27.5px;
  grid-template-columns: auto auto;
  gap: 27.5px;
  max-width: max-content;
  margin-bottom: -32px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  transform: translateY(-68px);
}

@media (min-width: 1024px) {
  .natrelle__fullness-img-section {
    grid-gap: var(--emu-common-spacing-xl);
    gap: var(--emu-common-spacing-xl);
    margin: var(--emu-common-spacing-none);
    z-index: var(--emu-common-other-z-index-layer);
    margin-top: -108px;
    position: relative;
    transform: translateY(158px);
  }
}

.natrelle__fullness-img-section .aaaem-image {
  width: 100%;
  max-width: 137px;
}

@media (min-width: 1024px) {
  .natrelle__fullness-img-section .aaaem-image {
    max-width: 318px;
  }
}

.natrelle__fullness-img-section .aaaem-image img {
  width: 100%;
}

@media (min-width: 1024px) {
  .natrelle__fullness-img-section .aaaem-image img {
    width: 318px;
  }
}

.natrelle__customer-section {
  margin-top: 96px;
}

@media (min-width: 1024px) {
  .natrelle__customer-section {
    margin-top: 128px;
  }
}

.natrelle__customer-section .aaaem-title {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .natrelle__customer-section .aaaem-title {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

.natrelle__customer-section .aaaem-text p {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .natrelle__customer-section .aaaem-text p {
    margin-bottom: 28px;
  }
}

.natrelle__customer-section .aaaem-text p:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

.about-section {
  padding-bottom: var(--emu-common-spacing-large);
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  margin-top: 77px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .about-section {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    -ms-flex-flow: row;
    flex-flow: row;
    -ms-flex-align: start;
    align-items: flex-start;
    column-gap: 40px;
    min-height: 595px;
    margin-top: 194px;
    padding: 55px;
  }
}

@media (min-width: 1440px) {
  .about-section {
    grid-column-gap: 105px;
    -webkit-column-gap: 105px;
    -moz-column-gap: 105px;
    column-gap: 105px;
  }
}

@media (min-width: 1024px) {
  .about-section .image-border:after {
    width: calc(100% - 10px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
  }

  .about-section > div.container {
    width: calc(50% + 204px);
  }

  .about-section > div.text {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.about-section__image-wrapper {
  margin-bottom: -30px;
  transform: translateY(-30px);
}

@media (min-width: 1024px) {
  .about-section__image-wrapper {
    margin-bottom: -93px;
    transform: translateY(-93px);
  }
}

@media (min-width: 1440px) {
  .about-section__image-wrapper {
    margin-bottom: -116px;
    transform: translateY(-116px);
  }
}

@media (min-width: 1024px) {
  .about-section__image-wrapper img {
    width: 100%;
    height: auto;
  }
}

.about-section__text {
  padding-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .about-section__text {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1440px) {
  .about-section__text {
    padding-right: 40px;
  }
}

.about-section__text p:not(:last-child) {
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .about-section__text p {
    line-height: 28px;
  }
}

.image-border {
  position: relative;
}

.image-border:after {
  content: "";
  border: 1.5px solid var(--emu-common-colors-white);
  pointer-events: none;
  border-right: none;
  width: calc(100% - 9px);
  height: calc(100% - 18px);
  display: block;
  position: absolute;
  top: 9px;
  left: 9px;
}

@media (min-width: 1024px) {
  .image-border:after {
    border-width: var(--emu-common-border-width-medium);
    width: calc(100% - 23px);
    height: calc(100% - 46px);
    top: 23px;
    left: 23px;
  }
}

.natrelle__image-disclaimer {
  position: absolute;
  bottom: 19px;
  left: 19px;
}

.cq-Editable-dom--container .natrelle__image-disclaimer {
  position: static;
}

@media (min-width: 1024px) {
  .natrelle__image-disclaimer {
    bottom: 20px;
    left: 20px;
  }
}

.natrelle__image-disclaimer p {
  font-size: 10px;
  line-height: var(--emu-semantic-font-sizes-narrow-xxl);
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  .natrelle__image-disclaimer p {
    font-size: 11px;
  }
}

.natrelle__carousel-image {
  max-width: 349px;
}

@media (min-width: 768px) {
  .natrelle__carousel-image {
    max-width: 500px;
  }
}

@media (min-width: 1024px) {
  .natrelle__carousel-image {
    max-width: 100%;
  }
}

.natrelle__carousel-image-section {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.natrelle__carousel-image-text a {
  border: 1.5px solid var(--emu-common-colors-white);
  color: var(--emu-common-colors-white);
  font-size: 15px;
  line-height: normal;
  font-weight: var(--emu-semantic-font-weight-325);
  padding: 10px 28px 9px 14px;
  text-decoration: none;
  position: absolute;
  bottom: 19px;
  left: 19px;
}

@media (min-width: 1024px) {
  .natrelle__carousel-image-text a {
    bottom: 41px;
    left: 40px;
  }
}

@media (min-width: 1200px) {
  .natrelle__carousel-image-text a {
    padding-top: 17px;
    padding-right: 46px;
    padding-bottom: var(--emu-common-spacing-medium);
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    padding-left: 28px;
  }
}

.natrelle__carousel-image-text a:after {
  content: "";
  background-image: url("resources/images/arrow_desktop.png");
  background-size: cover;
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}

@media (min-width: 1200px) {
  .natrelle__carousel-image-text a:after {
    width: 20px;
    height: 20px;
    right: 19px;
  }
}

@media (min-width: 1024px) {
  .natrelle__carousel-content {
    padding-left: var(--emu-common-spacing-none);
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    min-height: 500px;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .natrelle__carousel-content {
    min-height: 595px;
  }
}

.natrelle__carousel-content .cmp-title__text {
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: -1.28px;
  font-size: 32px;
}

@media (min-width: 1024px) {
  .natrelle__carousel-content .cmp-title__text {
    margin-top: var(--emu-common-spacing-none);
  }
}

.natrelle__carousel-content .cmp-title__text br {
  display: none;
}

@media (min-width: 1024px) {
  .natrelle__carousel-content .cmp-title__text br {
    display: block;
  }
}

@media (min-width: 1200px) {
  .natrelle__carousel-content .cmp-title__text {
    letter-spacing: -2.56px;
    margin-bottom: 28px;
    font-size: 64px;
  }
}

.natrelle__carousel-content p {
  font-size: 26px;
  line-height: normal;
  font-weight: var(--emu-common-font-weight-light);
  font-family: var(--emu-semantic-font-families-heading);
  margin-bottom: var(--emu-common-spacing-small);
  padding-left: 30px;
}

@media (min-width: 768px) {
  .natrelle__carousel-content p {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .natrelle__carousel-content p {
    font-size: 26px;
  }
}

.natrelle__carousel-content p .emphasis {
  position: relative;
}

.natrelle__carousel-content p .emphasis:before {
  content: "";
  background-color: var(--emu-semantic-colors-primary-navy-100);
  width: 20px;
  height: 2px;
  display: block;
  position: absolute;
  top: 14px;
  left: -26px;
}

@media (min-width: 1024px) {
  .natrelle__carousel-content p .emphasis:before {
    width: 20px;
    top: 13px;
    left: -31px;
  }
}

.natrelle__carousel-disclaimer-text p {
  font-family: var(--emu-semantic-font-families-body);
  font-size: 18px;
  line-height: normal;
  font-weight: var(--emu-common-font-weight-regular);
  margin-bottom: var(--emu-common-spacing-medium);
}

.natrelle__carousel-disclaimer-text sup {
  font-size: 61%;
  position: relative;
  top: 4px;
}

.natrelle__carousel-inner-container {
  grid-gap: 60px;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 60px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 1024px) {
  .natrelle__carousel-inner-container {
    grid-gap: var(--emu-common-sizing-none);
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: var(--emu-common-sizing-none);
    background-color: var(--emu-semantic-colors-secondary-mint-100);
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 65px;
    padding-left: 57px;
    padding-right: 57px;
  }

  .natrelle__carousel-inner-container > .container {
    width: calc(55% - 49px);
  }

  .natrelle__carousel-inner-container > .container:first-child {
    transform: translateY(-52px);
  }

  .natrelle__carousel-inner-container > .container:last-child {
    width: calc(45% - 50px);
  }
}

.natrelle__carousel-wrapper {
  margin-top: 163px;
}

@media (min-width: 1024px) {
  .natrelle__carousel-wrapper {
    background: none;
    margin-top: 193px;
  }
}

.gumminess {
  margin-bottom: 170px;
  padding-top: 44px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .gumminess {
    margin-top: 50px;
    margin-bottom: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-xl);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--emu-common-spacing-xl);
    grid-gap: 40px;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 40px;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1440px) {
  .gumminess {
    padding-left: 128px;
  }
}

.gumminess.image-border:after {
  display: none;
}

@media (min-width: 1024px) {
  .gumminess.image-border:after {
    top: var(--emu-common-spacing-none);
    left: var(--emu-common-spacing-medium);
    z-index: var(--emu-common-other-z-index-base);
    border-top: none;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    display: block;
  }
}

@media (min-width: 1440px) {
  .gumminess.image-border:after {
    left: var(--emu-common-spacing-large);
    width: calc(100% - 32px);
    height: calc(100% - 33px);
  }
}

@media (min-width: 1024px) {
  .gumminess > .container {
    width: 100%;
    max-width: 741px;
  }

  .gumminess > .embed {
    width: 100%;
    max-width: 734px;
  }

  .gumminess__text {
    padding-top: var(--emu-common-spacing-none);
    max-width: 689px;
    margin: auto;
    padding-bottom: 20px;
  }
}

.gumminess__text h4 {
  margin-bottom: var(--emu-common-spacing-medium);
  text-align: center;
}

@media (min-width: 1024px) {
  .gumminess__text h4 {
    text-align: left;
    margin-bottom: var(--emu-common-spacing-large);
  }
}

.gumminess__image-container {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 1024px) {
  .gumminess__image-container {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .gumminess__image-container > .image {
    -ms-flex: 1;
    flex: 1;
  }
}

@media (min-width: 768px) {
  .gumminess__image-container img {
    max-width: 400px;
    margin: auto;
  }
}

@media (min-width: 1024px) {
  .gumminess__image-container img {
    max-width: 100%;
  }
}

.gumminess__disclaimer {
  margin-bottom: 34px;
  margin-left: 9px;
}

@media (min-width: 1024px) {
  .gumminess__disclaimer {
    margin-bottom: var(--emu-common-spacing-none);
    margin-left: 26px;
  }
}

.gumminess__disclaimer p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
}

.gumminess__embed {
  z-index: var(--emu-common-other-z-index-layer);
  margin-top: -170px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  transform: translateY(170px);
}

@media (min-width: 1024px) {
  .gumminess__embed {
    margin-top: -100px;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(100px);
  }

  .natrelle__social-icon {
    width: 77px;
  }
}

.natrelle__social-icon img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.natrelle__social-icon-container {
  grid-gap: var(--emu-common-spacing-medium);
  -ms-flex-pack: center;
  justify-content: center;
  gap: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-xl);
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 1024px) {
  .natrelle__social-icon-container {
    grid-gap: 25px;
    margin-top: 96px;
    margin-right: var(--emu-common-spacing-xl);
    margin-left: var(--emu-common-spacing-xl);
    gap: 25px;
  }
}

.error-page {
  background-color: var(--emu-semantic-colors-primary-teal-100);
  padding: 100px 20px 60px;
}

@media (min-width: 1024px) {
  .error-page {
    padding-top: 250px;
    padding-bottom: 100px;
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
  }
}

.error-page > .button {
  text-align: center;
}

.error-page .cmp-title {
  color: var(--emu-common-colors-white);
}

.error-page .cmp-text {
  margin-top: var(--emu-common-spacing-large);
}

.error-page .cmp-text p, .error-page .cmp-text p a {
  color: var(--emu-common-colors-white);
}

.error-page .cmp-button {
  outline: 1.5px solid var(--emu-common-colors-white);
  outline-offset: var(--emu-common-spacing-xs);
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 12px 40px;
  font-size: 18px;
}

@media (min-width: 1024px) {
  .error-page .cmp-button {
    font-size: 26px;
  }
}

.aaaem-carousel {
  transform: translateY(-31px);
}

@media (min-width: 1024px) {
  .aaaem-carousel {
    transform: none;
  }
}

.aaaem-carousel__action {
  border-color: var(--emu-semantic-colors-primary-navy-100);
  bottom: unset;
  background-color: var(--emu-common-colors-transparent);
  top: max(60vw, 200px);
}

@media (min-width: 375px) {
  .aaaem-carousel__action {
    top: 248px;
  }
}

@media (min-width: 768px) {
  .aaaem-carousel__action {
    top: 340px;
  }
}

@media (min-width: 1024px) {
  .aaaem-carousel__action {
    border-width: 3px;
    padding: 5px 14px;
    top: 110px;
  }
}

@media (hover: hover) {
  .aaaem-carousel__action:not([disabled]):hover {
    border-color: var(--emu-semantic-colors-primary-teal-100);
    background-color: var(--emu-semantic-colors-primary-teal-100);
  }

  .aaaem-carousel__action:not([disabled]):hover:after {
    background-image: url("resources/images/carousel-arrow-white.png");
  }
}

.aaaem-carousel__action:after {
  content: "";
  width: var(--emu-common-sizing-medium);
  background-image: url("resources/images/carousel_arrow_desktop.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  display: block;
}

@media (min-width: 1024px) {
  .aaaem-carousel__action:after {
    width: 47px;
    height: 44px;
  }
}

.aaaem-carousel__action[disabled] {
  border-color: #afd7de;
}

.aaaem-carousel__action[disabled]:after {
  background-image: url("resources/images/arrow-disabled.png");
}

.aaaem-carousel__action-prev {
  left: 0;
}

.aaaem-carousel__action-prev:after {
  transform: rotate(180deg);
}

@media (min-width: 1024px) {
  .aaaem-carousel__action-prev {
    left: unset;
    right: 143px;
  }
}

.aaaem-carousel__action-next {
  right: 0;
}

@media (min-width: 1024px) {
  .aaaem-carousel__action-next {
    right: 48px;
  }
}

.aaaem-carousel__action-icon {
  display: none;
}

[data-component="container-isi-v2"].isi-scrollable .isi-container {
  z-index: var(--emu-component-containers-isi-z-index);
}

.aaaem-isi-holder {
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-xl);
}

@media (min-width: 1024px) {
  .aaaem-isi-holder {
    margin-top: var(--emu-common-spacing-xl);
    margin-bottom: 96px;
  }
}

.aaaem-isi-holder p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  margin: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-teal-100);
  padding-right: 40px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .aaaem-isi-holder p {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    padding-right: var(--emu-common-spacing-none);
    padding-left: 82px;
  }
}

.aaaem-isi-holder sup {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
}

.aaaem-isi-container.isi-container {
  max-width: var(--emu-semantic-sizing-breakpoints-max);
  left: unset;
  margin-left: auto;
  margin-right: auto;
}

.aaaem-isi-container.isi-container .aaaem-isi-container__button-wrapper {
  position: absolute;
  right: 28px;
}

.aaaem-isi-container.isi-container .plus-minus-icon:active {
  outline: revert;
}

.aaaem-isi-container.isi-container .plus-minus-icon:focus .aaaem-button__icon:before, .aaaem-isi-container.isi-container .plus-minus-icon:focus .aaaem-button__icon:after {
  background-color: var(--emu-common-colors-black);
}

@media (min-width: 1024px) {
  .aaaem-isi-container__isi-content {
    margin-left: 66px;
    margin-right: 66px;
  }
}

.aaaem-isi-container__isi-content h5 {
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-common-line-heights-wide-large);
  color: var(--emu-semantic-colors-primary-teal-100);
}

.aaaem-isi-container__isi-content p, .aaaem-isi-container__isi-content li {
  font-size: var(--emu-semantic-font-sizes-wide-medium);
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
  line-height: var(--emu-common-line-heights-wide-medium);
}

.aaaem-isi-container__isi-content p {
  margin-bottom: var(--emu-common-spacing-medium);
  padding: var(--emu-common-spacing-none);
}

.aaaem-isi-container__isi-content p a {
  color: var(--emu-semantic-colors-primary-teal-100);
}

.aaaem-isi-container__isi-content p a[href="#tel"] {
  color: var(--emu-semantic-colors-secondary-dark-gray-300);
  text-decoration: none;
}

.aaaem-isi-container__isi-content ul {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-large);
  padding-left: 22px;
}

@media (min-width: 1024px) {
  .aaaem-isi-container__isi-content ul {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.aaaem-isi-container__isi-content h5 + p {
  margin-bottom: var(--emu-common-spacing-none);
}

.aaaem-isi-container__isi-content .natrelle__isi-title {
  margin-bottom: var(--emu-common-spacing-large);
  margin-top: 1px;
}

@media (min-width: 1024px) {
  .aaaem-isi-container__isi-content .natrelle__isi-title {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.aaaem-isi-container__isi-content .natrelle__isi-text {
  margin-top: var(--emu-common-spacing-medium);
}

.aaaem-isi-container__isi-content .natrelle__isi-text p {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .aaaem-isi-container__isi-content .natrelle__isi-text p {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.plyr__controls {
  display: none;
}

.plyr__video-embed iframe {
  width: 101%;
  left: -1px;
}

footer.footer {
  background-color: var(--emu-common-colors-black);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  z-index: var(--emu-common-other-z-index-header);
  padding-top: 60px;
  padding-bottom: 102px;
  position: relative;
}

@media (min-width: 1024px) {
  footer.footer {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: var(--emu-common-spacing-xl);
  }
}

@media (min-width: 1200px) {
  footer.footer {
    padding-left: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-xl);
  }
}

@media (min-width: 1024px) {
  footer.footer .footer-links {
    grid-gap: var(--emu-common-spacing-large);
    gap: var(--emu-common-spacing-large);
    display: -ms-flexbox;
    display: flex;
  }
}

footer.footer .footer-links ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  list-style: none;
}

footer.footer .footer-links li {
  margin-bottom: var(--emu-common-spacing-large);
  text-align: center;
}

@media (min-width: 1024px) {
  footer.footer .footer-links li {
    text-align: left;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
  }
}

footer.footer .footer-links li a {
  color: var(--emu-common-colors-white);
  font-size: var(--emu-semantic-font-sizes-wide-xl);
  white-space: nowrap;
  line-height: normal;
  text-decoration: none;
}

@media (min-width: 1024px) {
  footer.footer .footer-links li a {
    font-size: var(--emu-common-font-sizes-wide-medium);
    font-weight: var(--emu-common-font-weight-bold);
  }
}

@media (min-width: 1440px) {
  footer.footer .footer-links li a {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
  }
}

footer.footer .footer-links li a img {
  height: 100%;
}

footer.footer .footer-top-container {
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-common-colors-white);
  padding-bottom: var(--emu-common-spacing-xl);
}

@media (min-width: 1024px) {
  footer.footer .footer-top-container {
    grid-column-gap: var(--emu-common-spacing-large);
    -webkit-column-gap: var(--emu-common-spacing-large);
    -moz-column-gap: var(--emu-common-spacing-large);
    -ms-flex-pack: justify;
    justify-content: space-between;
    column-gap: var(--emu-common-spacing-large);
    display: -ms-flexbox;
    display: flex;
  }
}

footer.footer .footer-top-container > .image {
  -ms-grid-row-align: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (min-width: 1024px) {
  footer.footer .footer-top-container > .text {
    -ms-flex: 1;
    flex: 1;
  }
}

footer.footer .footer-top-container .natrelle-logo {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  footer.footer .footer-top-container .natrelle-logo {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

footer.footer .footer-top-container .natrelle-logo img {
  max-width: 284px;
  margin: auto;
}

footer.footer .footer-top-container .footer-social-media {
  grid-gap: var(--emu-common-spacing-medium);
  -ms-flex-pack: center;
  justify-content: center;
  gap: var(--emu-common-spacing-medium);
  display: -ms-flexbox;
  display: flex;
}

footer.footer .footer-top-container .footer-social-media img {
  width: 47px;
}

@media (min-width: 1024px) {
  footer.footer .footer-top-container .footer-social-media img {
    width: 40px;
  }
}

@media (min-width: 1440px) {
  footer.footer .footer-top-container .footer-social-media img {
    width: 47px;
  }
}

@media (min-width: 1024px) {
  footer.footer .footer-top-container .footer-social-media {
    grid-gap: var(--emu-common-spacing-small);
    gap: var(--emu-common-spacing-small);
  }
}

@media (min-width: 1200px) {
  footer.footer .footer-top-container .footer-social-media {
    grid-gap: var(--emu-common-spacing-medium);
    gap: var(--emu-common-spacing-medium);
  }
}

footer.footer .footer-bottom-container {
  padding-top: var(--emu-common-spacing-xl);
}

@media (min-width: 1024px) {
  footer.footer .footer-bottom-container {
    grid-column-gap: 180px;
    -webkit-column-gap: 180px;
    -moz-column-gap: 180px;
    -ms-flex-pack: justify;
    justify-content: space-between;
    column-gap: 180px;
    display: -ms-flexbox;
    display: flex;
  }

  footer.footer .footer-bottom-container > .text {
    -ms-flex: 1;
    flex: 1;
  }
}

footer.footer .footer-bottom-container__logo {
  width: 270px;
}

footer.footer .footer-bottom-container .aaaem-text {
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  footer.footer .footer-bottom-container .aaaem-text {
    margin-top: var(--emu-common-spacing-none);
  }
}

footer.footer .footer-bottom-container .aaaem-text p {
  color: var(--emu-common-colors-white);
  margin-bottom: var(--emu-common-spacing-small);
  font-size: var(--emu-semantic-font-sizes-wide-xl);
  line-height: normal;
}

footer.footer .footer-bottom-container .aaaem-text a, footer.footer .footer-bottom-container .aaaem-text a:active, footer.footer .footer-bottom-container .aaaem-text a:focus, footer.footer .footer-bottom-container .aaaem-text a:hover {
  color: var(--emu-common-colors-white);
  text-decoration: none;
}

.header header {
  z-index: var(--emu-common-other-z-index-header);
  -webkit-transition: all var(--emu-common-other-time-transition-short) linear;
  transition: all var(--emu-common-other-time-transition-short) linear;
  width: 100%;
  position: absolute;
}

.header__logo .aaaem-image {
  max-width: 184px;
}

@media (min-width: 1024px) {
  .header__logo .aaaem-image {
    max-width: 240px;
  }
}

@media (min-width: 1440px) {
  .header__logo .aaaem-image {
    max-width: 400px;
  }
}

.header__logo .aaaem-image img {
  width: 100%;
}

.header__content {
  grid-gap: 15px;
  gap: 15px;
}

@media (min-width: 1024px) {
  .header__content {
    grid-gap: 24px;
    gap: 24px;
  }
}

.header__content .aaaem-image {
  max-width: var(--emu-common-sizing-medium);
}

@media (min-width: 1024px) {
  .header__content .aaaem-image {
    max-width: 45px;
  }
}

@media (min-width: 1200px) {
  .header__content .aaaem-image {
    max-width: 55px;
  }
}

.header__content .aaaem-image img {
  width: 100%;
}

.header .cmp-container-header, .header .header__content {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.header .cmp-container-header {
  padding: 15px 20px;
}

@media (min-width: 1024px) {
  .header .cmp-container-header {
    padding-top: 30px;
    padding-right: var(--emu-common-spacing-xl);
    padding-bottom: 46px;
    padding-left: 68px;
  }
}

.header .cmp-container-header .skip-menu-container, .header .cmp-container-header .header__global-navigation {
  display: none;
}





/*# sourceMappingURL=main.css.map */
