/* Common styles for image border  START*/
.image-border {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: 9px;
    height: calc(100% - 18px);
    width: calc(100% - 9px);
    border: 1.5px solid var(--emu-common-colors-white);
    border-right: none;
    pointer-events: none;

    @include mq('large') {
      border-width: var(--emu-common-border-width-medium);
      top: 23px;
      left: 23px;
      height: calc(100% - 46px);
      width: calc(100% - 23px);
    }
  }
}

/* Common styles for image border  END*/

/* Common styles for image disclaimer  START*/
.natrelle__image-disclaimer {
  position: absolute;
  bottom: 19px;
  left: 19px;

  @include aem-editor-view {
    position: static;
  }

  @include mq('large') {
    left: 20px;
    bottom: 20px;
  }

  p {
    font-size: 10px;
    line-height: var(--emu-semantic-font-sizes-narrow-xxl);
    color: var(--emu-common-colors-white);

    @include mq('large') {
      font-size: 11px;
    }
  }
}
/* Common styles for image disclaimer  END*/
