@font-face {
  font-family: 'Collier-Bold';
  src: url('./assets/fonts/Collier/Collier-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Collier-Book';
  src: url('./assets/fonts/Collier/Collier-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Collier-Extra-Condensed-Italic';
  src: url('./assets/fonts/Collier/Collier-Extra-Condensed-Italic.woff')
    format('woff');
  font-display: swap;
}
