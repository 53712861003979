.hero-mobile-banner {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 20px;
    padding-bottom: 35px;

    &-img {
      max-width: 213px; // to match the design
    }
  }

  &__disclaimer p {
    font-size: 10px;
    font-family: var(--emu-semantic-font-families-body);
    color: var(--emu-common-colors-white);
    margin-top: var(--emu-common-spacing-large);
    line-height: normal;
  }
}
