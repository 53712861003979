.natrelle__fullness {
  &-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 33px;
    margin-top: 163px;
    position: relative;

    @include mq('large') {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8%;
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-large);
      margin-top: 128px;
      margin-bottom: 160px;
      min-height: 595px;
    }

    @include mq('xx-large') {
      padding-left: 145px; // as per figma
      padding-right: 152px; // as per figma
      justify-content: space-between;
      gap: 152px;
    }

    &.image-border {
      &::after {
        display: none;

        @include mq('large') {
          display: block;
          border-top: none;
          border-right: var(--emu-common-border-width-medium) solid
            var(--emu-common-colors-white);
          top: var(--emu-common-spacing-none);
          left: var(--emu-common-spacing-medium);
          width: calc(100% - 32px);
          height: calc(100% - 16px);
          z-index: var(--emu-common-other-z-index-base);
        }

        @include mq('xx-large') {
          left: var(--emu-common-spacing-large);
          width: calc(100% - 64px);
          height: calc(100% - 34px);
        }
      }
    }
  }

  &-content {
    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
      max-width: 562px; // As per the design.
      padding-top: 47px;
      padding-bottom: 47px;
      letter-spacing: 0.3px;
    }

    br {
      display: none;
      @include mq('x-large') {
        display: block;
      }
    }

    .aaaem-title {
      margin-bottom: 14px;

      @include mq('large') {
        text-align: unset;
      }
    }
  }

  &-img-section {
    display: grid;
    grid-template-columns: auto auto;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
    gap: 27.5px; // As per the design.
    transform: translateY(-68px); // to match the design.
    margin-bottom: -32px;

    @include mq('large') {
      gap: var(--emu-common-spacing-xl);
      margin: var(--emu-common-spacing-none);
      margin-top: -108px;
      transform: translateY(158px);
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
    }

    .aaaem-image {
      max-width: 137px; // As per the design.
      width: 100%;

      @include mq('large') {
        max-width: 318px;
      }

      img {
        width: 100%;

        @include mq('large') {
          width: 318px; // As per the design.
        }
      }
    }
  }
}
