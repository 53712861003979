.error-page {
  background-color: var(--emu-semantic-colors-primary-teal-100);
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;

  @include mq('large') {
    padding-top: 250px;
    padding-bottom: 100px;
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
  }

  > .button {
    text-align: center;
  }

  .cmp-title {
    color: var(--emu-common-colors-white);
  }

  .cmp-text {
    margin-top: var(--emu-common-spacing-large);

    p {
      color: var(--emu-common-colors-white);

      a {
        color: var(--emu-common-colors-white);
      }
    }
  }

  .cmp-button {
    margin-top: 40px;
    margin-bottom: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 18px;
    outline: 1.5px solid var(--emu-common-colors-white);
    outline-offset: var(--emu-common-spacing-xs);

    @include mq('large') {
      font-size: 26px;
    }
  }
}
