.header {
  & header {
    position: absolute;
    width: 100%;
    z-index: var(--emu-common-other-z-index-header);
    transition: all var(--emu-common-other-time-transition-short) linear;
  }

  &__logo {
    .aaaem-image {
      max-width: 184px; // to match the design.

      @include mq('large') {
        max-width: 240px;
      }

      @include mq('xx-large') {
        max-width: 400px;
      }

      img {
        width: 100%;
      }
    }
  }

  &__content {
    gap: 15px;

    @include mq('large') {
      gap: 24px;
    }

    .aaaem-image {
      max-width: var(--emu-common-sizing-medium); // to match the design.

      @include mq('large') {
        max-width: 45px;
      }

      @include mq('x-large') {
        max-width: 55px;
      }

      img {
        width: 100%;
      }
    }
  }

  .cmp-container-header,
  .header__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cmp-container-header {
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 15px;

    @include mq('large') {
      padding-top: 30px;
      padding-right: var(--emu-common-spacing-xl);
      padding-left: 68px;
      padding-bottom: 46px;
    }

    .skip-menu-container,
    .header__global-navigation {
      display: none;
    }
  }
}
