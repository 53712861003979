footer.footer {
  background-color: var(--emu-common-colors-black);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-top: 60px;
  padding-bottom: 102px;
  position: relative;
  z-index: var(--emu-common-other-z-index-header);

  @include mq('large') {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: var(--emu-common-spacing-xl);
  }

  @include mq('x-large') {
    padding-left: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-xl);
  }

  .footer-links {
    @include mq('large') {
      display: flex;
      gap: var(--emu-common-spacing-large);
    }
    ul {
      padding: var(--emu-common-spacing-none);
      margin: var(--emu-common-spacing-none);
      list-style: none;
    }

    li {
      margin-bottom: var(--emu-common-spacing-large);
      text-align: center;

      @include mq('large') {
        text-align: left;
        display: flex;
        align-items: center;
      }

      a {
        color: var(--emu-common-colors-white);
        text-decoration: none;
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: normal;
        white-space: nowrap;

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          font-weight: var(--emu-common-font-weight-bold);
        }

        @include mq('xx-large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
        }

        img {
          height: 100%;
        }
      }
    }
  }

  .footer-top-container {
    border-bottom: var(--emu-common-border-width-medium) solid
      var(--emu-common-colors-white);
    padding-bottom: var(--emu-common-spacing-xl);

    @include mq('large') {
      display: flex;
      justify-content: space-between;
      column-gap: var(--emu-common-spacing-large);
    }

    > .image {
      align-self: center;
    }

    > .text {
      @include mq('large') {
        flex: 1;
      }
    }

    .natrelle-logo {
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      img {
        margin: auto;
        max-width: 284px;
      }
    }

    .footer-social-media {
      display: flex;
      justify-content: center;
      gap: var(--emu-common-spacing-medium);

      img {
        width: 47px; // from design

        @include mq('large') {
          width: 40px;
        }

        @include mq('xx-large') {
          width: 47px;
        }
      }

      @include mq('large') {
        gap: var(--emu-common-spacing-small);
      }

      @include mq('x-large') {
        gap: var(--emu-common-spacing-medium);
      }
    }
  }

  .footer-bottom-container {
    padding-top: var(--emu-common-spacing-xl);

    @include mq('large') {
      display: flex;
      justify-content: space-between;
      column-gap: 180px;

      > .text {
        flex: 1;
      }
    }

    &__logo {
      width: 270px; // value from the design
    }

    .aaaem-text {
      margin-top: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
      }

      p {
        color: var(--emu-common-colors-white);
        margin-bottom: var(--emu-common-spacing-small);
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: normal;
      }

      a {
        &,
        &:active,
        &:focus,
        &:hover {
          color: var(--emu-common-colors-white);
          text-decoration: none;
        }
      }
    }
  }
}
