.natrelle {
  &__carousel {
    &-image {
      max-width: 349px; // to match the design.

      @include mq('medium') {
        max-width: 500px;
      }

      @include mq('large') {
        max-width: 100%;
      }

      &-section {
        position: relative;
        width: max-content; // to match the design.
        margin-left: auto;
        margin-right: auto;
      }

      &-text {
        a {
          position: absolute;
          bottom: 19px; // to match the design.
          left: 19px; // to match the design.
          padding-top: 10px;
          padding-right: 28px;
          padding-bottom: 9px;
          padding-left: 14px;
          border: 1.5px solid var(--emu-common-colors-white);
          color: inherit;
          text-decoration: none;
          font-size: 15px;
          line-height: normal;
          color: var(--emu-common-colors-white);
          font-weight: var(--emu-semantic-font-weight-325);

          @include mq('large') {
            bottom: 41px;
            left: 40px;
          }

          @include mq('x-large') {
            padding-top: 17px;
            padding-right: 46px;
            padding-bottom: var(--emu-common-spacing-medium);
            padding-left: 28px;
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }

          &::after {
            content: '';
            background-image: url('./assets/images/arrow_desktop.png');
            background-size: cover;
            display: inline-block;
            width: 12px; // to match the design.
            height: 12px; // to match the design.
            position: absolute;
            top: 50%;
            right: 11px;
            transform: translateY(-50%);

            @include mq('x-large') {
              width: 20px;
              height: 20px;
              right: 19px;
            }
          }
        }
      }
    }

    &-content {
      @include mq('large') {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding-left: var(--emu-common-spacing-none);
        min-height: 500px;
      }

      @include mq('x-large') {
        min-height: 595px;
      }

      .cmp-title__text {
        margin-top: var(--emu-common-spacing-large);
        margin-bottom: var(--emu-common-spacing-medium);
        font-size: 32px;
        letter-spacing: -1.28px; // As per the design.

        @include mq('large') {
          margin-top: var(--emu-common-spacing-none);
        }

        br {
          display: none;
          @include mq('large') {
            display: block;
          }
        }

        // Needed this breakpoint to make it look good.
        @include mq('x-large') {
          font-size: 64px;
          letter-spacing: -2.56px; // As per the design.
          margin-bottom: 28px;
        }
      }

      p {
        font-size: 26px;
        line-height: normal;
        font-weight: var(--emu-common-font-weight-light);
        font-family: var(--emu-semantic-font-families-heading);
        margin-bottom: var(--emu-common-spacing-small);
        padding-left: 30px;

        @include mq('medium') {
          font-size: 22px;
        }

        @include mq('x-large') {
          font-size: 26px;
        }

        .emphasis {
          // Adding styles for line preceding the text.
          position: relative;

          &::before {
            content: '';
            display: block;
            background-color: var(--emu-semantic-colors-primary-navy-100);
            width: 20px;
            height: 2px;
            position: absolute;
            top: 14px;
            left: -26px;

            @include mq('large') {
              width: 20px;
              top: 13px;
              left: -31px;
            }
          }
        }
      }
    }

    &-disclaimer-text {
      p {
        font-family: var(--emu-semantic-font-families-body);
        font-size: 18px;
        line-height: normal;
        font-weight: var(--emu-common-font-weight-regular);
        margin-bottom: var(--emu-common-spacing-medium);
      }

      sup {
        top: 4px;
        position: relative;
        font-size: 61%;
      }
    }

    &-inner-container {
      display: flex;
      flex-direction: column;
      gap: 60px; // As per the design.

      @include mq('large') {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 57px;
        padding-left: 57px;
        gap: var(--emu-common-sizing-none);
        background-color: var(--emu-semantic-colors-secondary-mint-100);
        margin-top: 65px;

        > .container {
          width: calc(55% - 49px); // to match the design.

          &:first-child {
            transform: translateY(-52px); // to match the design.
          }

          &:last-child {
            width: calc(45% - 50px);
          }
        }
      }
    }

    &-wrapper {
      margin-top: 163px;

      @include mq('large') {
        margin-top: 193px;
        background: none; // to match the design.
      }
    }
  }
}
