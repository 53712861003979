.natrelle__image-grid {
  &-container {
    display: flex;
    flex-direction: column;
    margin-top: 147px;
    margin-bottom: 96px;
    padding-bottom: 30px;
    min-height: 200px;

    @include mq('large') {
      flex-direction: row;
      justify-content: center;
      gap: 37px;
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: 103px;
      margin-top: 283px;
      margin-bottom: 128px;
    }

    > .container {
      display: inline-flex;

      @include mq('large') {
        width: calc(50% - 80px);
        align-self: stretch;
        max-width: 739px; // to match the design
      }

      .image-border {
        &::after {
          border: 1.5px solid var(--emu-common-colors-white);
          height: calc(100% - 11px); // to match the design.
          pointer-events: none;

          @include mq('large') {
            width: calc(100% - 21px);
          }
        }
      }

      &:first-child {
        transform: translateY(-34px);

        @include mq('large') {
          transform: none;
        }

        .image-border {
          &::after {
            top: var(--emu-common-sizing-none);
            left: var(--emu-common-sizing-none);
            border-left: none;
            border-top: none;

            @include mq('large') {
              height: calc(100% - 21px);
            }
          }
        }
      }

      &:last-child {
        .image-border {
          &::after {
            width: calc(100% - 11px); // to match the design.
            top: 11px;
            left: 11px;
            border-right: none;
            border-bottom: none;

            @include mq('large') {
              height: calc(100% - 25px);
              top: 25px;
              left: 21px;
            }
          }
        }
      }
    }
  }

  &-inner-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @include mq('large') {
      margin: unset;
      margin-top: -118px; // to match the design.
      width: 100%;
    }

    .natrelle__image-disclaimer {
      bottom: 22px;
      left: 24px;
      font-size: 11px;
      pointer-events: none;

      @include mq('large') {
        left: 55px;
        bottom: var(--emu-common-spacing-large);
      }
    }
  }

  &-image {
    overflow: hidden;
    max-width: 350px; // to match the design.

    @include mq('medium') {
      max-width: 560px;
    }

    @include mq('large') {
      max-width: 100%;
    }

    img {
      transition: all 0.6s ease-in-out;
      width: 100%;
    }

    &:hover {
      img {
        transform: scale(1.3); // to match the design.
      }
    }
  }

  &-content {
    position: absolute;
    bottom: 50px; // to match the design.
    left: 24px;
    pointer-events: none;

    @include aem-editor-view {
      position: static;
    }

    @include mq('large') {
      left: 55px;
      bottom: 83px;
    }

    .aaaem-text p,
    .aaaem-title {
      color: var(--emu-common-colors-white);
    }

    .aaaem-text p {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: normal;
      letter-spacing: 1px; // to match the design.

      @include mq('x-large') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
      }
    }

    .cmp-title__text {
      margin-bottom: var(--emu-common-spacing-xs);

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
      }

      @include mq('x-large') {
        font-size: var(--emu-semantic-font-sizes-wide-xxxl);
      }

      // arrow after text
      &::after {
        content: '';
        background-image: url('./assets/images/arrow_desktop.png');
        background-size: cover;
        display: inline-block;
        width: 18px; // to match the design.
        height: 17px; // to match the design.
        margin-left: var(--emu-common-spacing-xs);

        @include mq('x-large') {
          width: var(--emu-common-sizing-medium);
          height: 30px;
        }
      }
    }
  }
}
