// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--root {
  margin-left: auto;
  margin-right: auto;
}

// content related util classes. Used to center buttons mostly.
.content--centered {
  text-align: center;
}

.container--primary {
  padding-left: 20px;
  padding-right: 20px;

  @include mq('large') {
    margin-left: var(--emu-common-spacing-xl);
    margin-right: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.container--secondary {
  margin-right: 20px;
  margin-left: 20px;
  padding-top: var(--emu-common-spacing-large);
  padding-right: 25px;
  padding-bottom: var(--emu-common-spacing-large);
  padding-left: 25px;

  @include mq('large') {
    padding-right: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-xl);
    margin-right: auto;
    margin-left: auto;
    max-width: 928px; // as per figma
  }
}

.bg--mint {
  background-color: var(--emu-semantic-colors-secondary-mint-100);
}

.bg--light-gray {
  background-color: var(--emu-semantic-colors-secondary-light-gray-100);
}
