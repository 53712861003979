
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-common-spacing-xs: 4px !default;
$emu-common-spacing-xxs: 2px !default;
$emu-common-spacing-small: 8px !default;
$emu-common-spacing-medium: 16px !default;
$emu-common-spacing-large: 32px !default;
$emu-common-spacing-xl: 64px !default;
$emu-common-spacing-none: 0px !default;
$emu-common-spacing-unset: unset !default;
$emu-common-colors-white: #FFFFFF !default;
$emu-common-colors-black: #000000 !default;
$emu-common-colors-red-100: #FF7570 !default;
$emu-common-colors-red-200: #FF615C !default;
$emu-common-colors-red-300: #FF4E47 !default;
$emu-common-colors-red-400: #FF3A33 !default;
$emu-common-colors-red-500: #FF1D15 !default;
$emu-common-colors-red-600: #FF120A !default;
$emu-common-colors-red-700: #F50800 !default;
$emu-common-colors-red-800: #E00700 !default;
$emu-common-colors-red-900: #CC0700 !default;
$emu-common-colors-grey-100: #F4F5F5 !default;
$emu-common-colors-grey-200: #D4D7D8 !default;
$emu-common-colors-grey-300: #B4B8BB !default;
$emu-common-colors-grey-400: #949A9E !default;
$emu-common-colors-grey-500: #7E868B !default;
$emu-common-colors-grey-600: #61676B !default;
$emu-common-colors-grey-700: #44484B !default;
$emu-common-colors-grey-800: #303436 !default;
$emu-common-colors-grey-900: #1D1F20 !default;
$emu-common-colors-transparent: rgba(0,0,0,0) !default;
$emu-common-font-families-sans: Arial !default;
$emu-common-font-families-serif: Times New Roman !default;
$emu-common-font-families-mono: Courier !default;
$emu-common-font-weight-bold: 700 !default;
$emu-common-font-weight-light: 300 !default;
$emu-common-font-weight-black: 900 !default;
$emu-common-font-weight-regular: 400 !default;
$emu-common-font-sizes-narrow-medium: 12px !default;
$emu-common-font-sizes-narrow-large: 16.97px !default;
$emu-common-font-sizes-narrow-xl: 23.99px !default;
$emu-common-font-sizes-narrow-xxl: 33.93px !default;
$emu-common-font-sizes-narrow-xxxl: 47.97px !default;
$emu-common-font-sizes-wide-medium: 16px !default;
$emu-common-font-sizes-wide-large: 22.62px !default;
$emu-common-font-sizes-wide-xl: 31.99px !default;
$emu-common-font-sizes-wide-xxl: 45.23px !default;
$emu-common-font-sizes-wide-xxxl: 63.96px !default;
$emu-common-line-heights-narrow-large: 18px !default;
$emu-common-line-heights-narrow-medium: 18px !default;
$emu-common-line-heights-narrow-xl: 36px !default;
$emu-common-line-heights-narrow-xxl: 36px !default;
$emu-common-line-heights-narrow-xxxl: 54px !default;
$emu-common-line-heights-wide-large: 24px !default;
$emu-common-line-heights-wide-medium: 24px !default;
$emu-common-line-heights-wide-xl: 48px !default;
$emu-common-line-heights-wide-xxl: 48px !default;
$emu-common-line-heights-wide-xxxl: 72px !default;
$emu-common-border-radius-xxs: 2px !default;
$emu-common-border-radius-xs: 4px !default;
$emu-common-border-radius-small: 8px !default;
$emu-common-border-radius-medium: 16px !default;
$emu-common-border-radius-large: 32px !default;
$emu-common-border-radius-none: 0px !default;
$emu-common-border-width-thin: 1px !default;
$emu-common-border-width-medium: 2px !default;
$emu-common-border-width-thick: 4px !default;
$emu-common-border-width-none: 0px !default;
$emu-common-outline-width-thin: thin !default;
$emu-common-outline-width-medium: medium !default;
$emu-common-outline-width-thick: thick !default;
$emu-common-outline-width-unset: unset !default;
$emu-common-sizing-none: 0px !default;
$emu-common-sizing-xxs: 1px !default;
$emu-common-sizing-xs: 8px !default;
$emu-common-sizing-small: 16px !default;
$emu-common-sizing-medium: 32px !default;
$emu-common-sizing-large: 64px !default;
$emu-common-sizing-xl: 128px !default;
$emu-common-sizing-xxl: 256px !default;
$emu-common-sizing-container: 1520px !default;
$emu-common-other-time-transition-short: 200ms !default;
$emu-common-other-time-transition-base: 400ms !default;
$emu-common-other-time-transition-long: 600ms !default;
$emu-common-other-time-transition-xl: 1000ms !default;
$emu-common-other-time-duration-instant: 400ms !default;
$emu-common-other-time-duration-short: 2000ms !default;
$emu-common-other-time-duration-base: 4000ms !default;
$emu-common-other-time-duration-long: 6000ms !default;
$emu-common-other-time-delay-none: 0ms !default;
$emu-common-other-time-delay-short: 50ms !default;
$emu-common-other-time-delay-base: 100ms !default;
$emu-common-other-time-delay-long: 200ms !default;
$emu-common-other-z-index-cookie-banner: 700 !default;
$emu-common-other-z-index-modal: 600 !default;
$emu-common-other-z-index-header: 500 !default;
$emu-common-other-z-index-isi: 400 !default;
$emu-common-other-z-index-overlay: 300 !default;
$emu-common-other-z-index-layer: 100 !default;
$emu-common-other-z-index-base: 0 !default;
$emu-common-other-z-index-behind: -1 !default;

$common: (
  'common': (
    'spacing': (
      'xs': $emu-common-spacing-xs,
      'xxs': $emu-common-spacing-xxs,
      'small': $emu-common-spacing-small,
      'medium': $emu-common-spacing-medium,
      'large': $emu-common-spacing-large,
      'xl': $emu-common-spacing-xl,
      'none': $emu-common-spacing-none,
      'unset': $emu-common-spacing-unset
    ),
    'colors': (
      'white': $emu-common-colors-white,
      'black': $emu-common-colors-black,
      'red': (
        '100': $emu-common-colors-red-100,
        '200': $emu-common-colors-red-200,
        '300': $emu-common-colors-red-300,
        '400': $emu-common-colors-red-400,
        '500': $emu-common-colors-red-500,
        '600': $emu-common-colors-red-600,
        '700': $emu-common-colors-red-700,
        '800': $emu-common-colors-red-800,
        '900': $emu-common-colors-red-900
      ),
      'grey': (
        '100': $emu-common-colors-grey-100,
        '200': $emu-common-colors-grey-200,
        '300': $emu-common-colors-grey-300,
        '400': $emu-common-colors-grey-400,
        '500': $emu-common-colors-grey-500,
        '600': $emu-common-colors-grey-600,
        '700': $emu-common-colors-grey-700,
        '800': $emu-common-colors-grey-800,
        '900': $emu-common-colors-grey-900
      ),
      'transparent': $emu-common-colors-transparent
    ),
    'fontFamilies': (
      'sans': $emu-common-font-families-sans,
      'serif': $emu-common-font-families-serif,
      'mono': $emu-common-font-families-mono
    ),
    'fontWeight': (
      'bold': $emu-common-font-weight-bold,
      'light': $emu-common-font-weight-light,
      'black': $emu-common-font-weight-black,
      'regular': $emu-common-font-weight-regular
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-common-font-sizes-narrow-medium,
        'large': $emu-common-font-sizes-narrow-large,
        'xl': $emu-common-font-sizes-narrow-xl,
        'xxl': $emu-common-font-sizes-narrow-xxl,
        'xxxl': $emu-common-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-common-font-sizes-wide-medium,
        'large': $emu-common-font-sizes-wide-large,
        'xl': $emu-common-font-sizes-wide-xl,
        'xxl': $emu-common-font-sizes-wide-xxl,
        'xxxl': $emu-common-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-common-line-heights-narrow-large,
        'medium': $emu-common-line-heights-narrow-medium,
        'xl': $emu-common-line-heights-narrow-xl,
        'xxl': $emu-common-line-heights-narrow-xxl,
        'xxxl': $emu-common-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-common-line-heights-wide-large,
        'medium': $emu-common-line-heights-wide-medium,
        'xl': $emu-common-line-heights-wide-xl,
        'xxl': $emu-common-line-heights-wide-xxl,
        'xxxl': $emu-common-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xxs': $emu-common-border-radius-xxs,
      'xs': $emu-common-border-radius-xs,
      'small': $emu-common-border-radius-small,
      'medium': $emu-common-border-radius-medium,
      'large': $emu-common-border-radius-large,
      'none': $emu-common-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-common-border-width-thin,
      'medium': $emu-common-border-width-medium,
      'thick': $emu-common-border-width-thick,
      'none': $emu-common-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-common-outline-width-thin,
      'medium': $emu-common-outline-width-medium,
      'thick': $emu-common-outline-width-thick,
      'unset': $emu-common-outline-width-unset
    ),
    'sizing': (
      'none': $emu-common-sizing-none,
      'xxs': $emu-common-sizing-xxs,
      'xs': $emu-common-sizing-xs,
      'small': $emu-common-sizing-small,
      'medium': $emu-common-sizing-medium,
      'large': $emu-common-sizing-large,
      'xl': $emu-common-sizing-xl,
      'xxl': $emu-common-sizing-xxl,
      'container': $emu-common-sizing-container
    ),
    'other': (
      'time': (
        'transition': (
          'short': $emu-common-other-time-transition-short,
          'base': $emu-common-other-time-transition-base,
          'long': $emu-common-other-time-transition-long,
          'xl': $emu-common-other-time-transition-xl
        ),
        'duration': (
          'instant': $emu-common-other-time-duration-instant,
          'short': $emu-common-other-time-duration-short,
          'base': $emu-common-other-time-duration-base,
          'long': $emu-common-other-time-duration-long
        ),
        'delay': (
          'none': $emu-common-other-time-delay-none,
          'short': $emu-common-other-time-delay-short,
          'base': $emu-common-other-time-delay-base,
          'long': $emu-common-other-time-delay-long
        )
      ),
      'zIndex': (
        'cookieBanner': $emu-common-other-z-index-cookie-banner,
        'modal': $emu-common-other-z-index-modal,
        'header': $emu-common-other-z-index-header,
        'isi': $emu-common-other-z-index-isi,
        'overlay': $emu-common-other-z-index-overlay,
        'layer': $emu-common-other-z-index-layer,
        'base': $emu-common-other-z-index-base,
        'behind': $emu-common-other-z-index-behind
      )
    )
  )
);
