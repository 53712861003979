.aaaem-carousel {
  transform: translateY(-31px); // to match the design.

  @include mq('large') {
    transform: none;
  }

  &__action {
    border-color: var(--emu-semantic-colors-primary-navy-100);
    bottom: unset;
    top: max(60vw, 200px); // to match the design.
    background-color: var(--emu-common-colors-transparent);

    @include mq('375px') {
      top: 248px;
    }

    @include mq('medium') {
      top: 340px;
    }

    @include mq('large') {
      top: 110px;
      border-width: 3px;
      padding-right: 14px;
      padding-left: 14px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    @media (hover: hover) {
      &:not([disabled]):hover {
        border-color: var(--emu-semantic-colors-primary-teal-100);
        background-color: var(--emu-semantic-colors-primary-teal-100);
        &::after {
          background-image: url('../../assets/images/carousel-arrow-white.png');
        }
      }
    }

    &::after {
      content: '';
      display: block;
      background-image: url('../../assets/images/carousel_arrow_desktop.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: var(--emu-common-sizing-medium); // As per the design.
      height: 30px; // As per the design.

      @include mq('large') {
        width: 47px;
        height: 44px;
      }
    }

    &[disabled] {
      border-color: #afd7de;

      &::after {
        background-image: url('../../assets/images/arrow-disabled.png');
      }
    }

    &-prev {
      left: 0;

      &::after {
        transform: rotate(180deg); // As per the design.
      }

      @include mq('large') {
        left: unset;
        right: 143px;
      }
    }

    &-next {
      right: 0;

      @include mq('large') {
        right: 48px;
      }
    }

    &-icon {
      display: none;
    }
  }
}
