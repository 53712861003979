.natrelle__customer-section {
  margin-top: 96px;

  @include mq('large') {
    margin-top: 128px;
  }

  .aaaem-title {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-large);
    }
  }

  .aaaem-text {
    p {
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('large') {
        margin-bottom: 28px;
      }

      &:last-child {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }
  }
}
