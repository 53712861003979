.gumminess {
  padding-top: 44px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 170px;

  @include mq('large') {
    margin-top: 50px;
    margin-bottom: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-xl);
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: var(--emu-common-spacing-xl);
    display: flex;
    gap: 40px;
    justify-content: space-between;
  }

  @include mq('xx-large') {
    padding-left: 128px;
  }

  &.image-border {
    &::after {
      display: none;

      @include mq('large') {
        display: block;
        border-top: none;
        top: var(--emu-common-spacing-none);
        left: var(--emu-common-spacing-medium);
        height: calc(100% - 16px);
        width: calc(100% - 16px);
        z-index: var(--emu-common-other-z-index-base);
      }

      @include mq('xx-large') {
        left: var(--emu-common-spacing-large);
        height: calc(100% - 33px);
        width: calc(100% - 32px);
      }
    }
  }

  > .container {
    @include mq('large') {
      width: 100%;
      max-width: 741px; // as per figma
    }
  }

  > .embed {
    @include mq('large') {
      width: 100%;
      max-width: 734px; // as per figma
    }
  }

  &__text {
    @include mq('large') {
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: 20px;
      max-width: 689px; // as per figma
      margin: auto;
    }

    h4 {
      margin-bottom: var(--emu-common-spacing-medium);
      text-align: center;

      @include mq('large') {
        text-align: left;
        margin-bottom: var(--emu-common-spacing-large);
      }
    }
  }

  &__image-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq('large') {
      flex-direction: row;

      > .image {
        flex: 1;
      }
    }

    img {
      @include mq('medium') {
        max-width: 400px;
        margin: auto;
      }

      @include mq('large') {
        max-width: 100%;
      }
    }
  }

  &__disclaimer {
    margin-bottom: 34px;
    margin-left: 9px;

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: 26px;
    }

    p {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
    }
  }

  &__embed {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -170px;
    transform: translateY(170px);
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);

    @include mq('large') {
      margin-top: -100px;
      transform: translateY(100px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}
