.natrelle__fas {
  &-section {
    @include mq('large') {
      padding-top: var(--emu-common-spacing-xl);
      padding-bottom: var(--emu-common-spacing-xl);
      max-width: 1139px; // as per figma
    }

    .aaaem-title {
      margin-bottom: var(--emu-common-spacing-medium);
    }

    .cmp-form {
      position: relative;

      &-text {
        border: 1.5px solid var(--emu-common-colors-white); // As per the design.
        padding: var(--emu-common-spacing-small);

        @include mq('large') {
          padding: 9px;
        }

        &__text {
          width: 100%;
          border: none;
          padding-top: var(--emu-common-spacing-medium);
          padding-bottom: var(--emu-common-spacing-medium);
          padding-left: 15px;
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: normal;
          color: var(--emu-semantic-colors-secondary-dark-gray-200);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 23px;
          }
        }
      }

      .button {
        position: absolute;

        .cmp-form-button {
          position: absolute;
          right: 15px;
          top: 14px;
          font-size: var(--emu-common-sizing-none); // to match figma design.

          @include mq('large') {
            top: 19px;
            right: 19px;
          }

          &::after {
            content: '';
            position: relative;
            display: block;
            background-image: url('./assets/images/fas_icon_desktop.png');
            background-size: cover;
            width: 40px; // to match figma design.
            height: 38px; // to match figma design.

            @include mq('large') {
              width: 48px;
              height: 46px;
            }
          }
        }
      }

      &.form-has-error {
        .button {
          .cmp-form-button {
            opacity: 0.4;
            pointer-events: none;
          }
        }
      }
    }
  }

  &-inner-container {
    > .container {
      max-width: 300px; // as per design
      margin: auto;

      @include mq('large') {
        max-width: 638px;
      }
    }

    .fas-url {
      display: none;
    }

    .error-message {
      color: var(--emu-common-colors-red-600);
      margin-top: var(--emu-common-spacing-small);
      margin-left: var(--emu-common-spacing-xxs);
    }
  }
}

// authoring styles to make the link visible in authoring all the time
.fas-url {
  @include aem-editor-view {
    display: block !important;
  }
}
