.find-your-fullness {
  margin-top: 96px;
  margin-bottom: 96px;
  padding-top: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);

  @include mq('large') {
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--emu-common-spacing-none);
  }

  &__text {
    margin-bottom: var(--emu-common-spacing-large);

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-xl);
    }

    h4 {
      text-align: center;

      br {
        display: block;

        @include mq('large') {
          display: none;
        }
      }
    }

    p {
      margin-top: var(--emu-common-spacing-medium);

      @include mq('large') {
        margin-top: var(--emu-common-spacing-large);
      }
    }
  }
}
