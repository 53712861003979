body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
  color: var(--emu-semantic-colors-primary-navy-100);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--emu-semantic-font-families-heading);
  color: inherit;
  margin: var(--emu-common-spacing-none);
  line-height: normal;
}

h4 {
  letter-spacing: -1.28px; // as per figma design

  @include mq('large') {
    letter-spacing: -2.24px; // as per figma design
  }

  i {
    font-family: var(--emu-semantic-font-families-collier-light-italic);
  }
}

p {
  color: var(--emu-semantic-colors-primary-navy-100);
  margin: var(--emu-common-spacing-none);
}

sup {
  line-height: 0px;
  font-size: 60%;
}
