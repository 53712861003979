.about-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 77px;
  padding-bottom: var(--emu-common-spacing-large);
  position: relative;

  @include mq('large') {
    flex-direction: row;
    margin-top: 194px;
    column-gap: 40px;
    padding-left: 55px;
    padding-bottom: 55px;
    padding-right: 55px;
    padding-top: 55px;
    align-items: flex-start;
    flex-wrap: nowrap;
    min-height: 595px;
  }

  @include mq('xx-large') {
    column-gap: 105px;
  }

  .image-border {
    @include mq('large') {
      &::after {
        top: 10px;
        left: 10px;
        height: calc(100% - 20px);
        width: calc(100% - 10px);
      }
    }
  }

  > div {
    &.container {
      @include mq('large') {
        width: calc(50% + 204px);
      }
    }

    &.text {
      @include mq('large') {
        width: 50%;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  &__image-wrapper {
    margin-bottom: -30px;
    transform: translateY(-30px);

    @include mq('large') {
      margin-bottom: -93px;
      transform: translateY(-93px);
    }

    @include mq('xx-large') {
      margin-bottom: -116px;
      transform: translateY(-116px);
    }

    img {
      @include mq('large') {
        width: 100%;
        height: auto;
      }
    }
  }

  &__text {
    padding-top: var(--emu-common-spacing-large);

    @include mq('large') {
      padding-top: var(--emu-common-spacing-none);
    }

    @include mq('xx-large') {
      padding-right: 40px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @include mq('large') {
        line-height: 28px;
      }
    }
  }
}
