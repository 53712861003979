.natrelle__social-icon {
  @include mq('large') {
    width: 77px; // As per the design.
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: var(--emu-common-spacing-medium);
    margin-top: var(--emu-common-spacing-xl);

    @include mq('large') {
      gap: 25px;
      margin-top: 96px;
      margin-right: var(--emu-common-spacing-xl);
      margin-left: var(--emu-common-spacing-xl);
    }
  }
}
