[data-component='container-isi-v2'].isi-scrollable .isi-container {
  z-index: var(--emu-component-containers-isi-z-index);
}

.aaaem-isi {
  &-holder {
    margin-top: var(--emu-common-spacing-large); // As per design.
    margin-bottom: var(--emu-common-spacing-xl);

    @include mq('large') {
      margin-top: var(--emu-common-spacing-xl);
      margin-bottom: 96px;
    }

    p {
      font-size: var(--emu-semantic-font-sizes-narrow-xxl);
      line-height: normal;
      margin: var(--emu-common-spacing-none);
      padding-right: 40px;
      color: var(--emu-semantic-colors-primary-teal-100);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        padding-right: var(--emu-common-spacing-none);
        padding-left: 82px;
      }
    }

    sup {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
    }
  }

  &-container {
    &.isi-container {
      max-width: var(--emu-semantic-sizing-breakpoints-max); // As per design.
      margin-left: auto;
      margin-right: auto;
      left: unset;

      .aaaem-isi-container__button-wrapper {
        position: absolute;
        right: 28px; // to match the design.
      }

      .plus-minus-icon {
        &:active {
          outline: revert;
        }

        &:focus {
          .aaaem-button__icon {
            &::before,
            &::after {
              background-color: var(--emu-common-colors-black);
            }
          }
        }
      }
    }

    &__isi-content {
      @include mq('large') {
        margin-left: 66px;
        margin-right: 66px;
      }

      h5 {
        font-family: var(--emu-semantic-font-families-body);
        line-height: var(--emu-common-line-heights-wide-large);
        color: var(--emu-semantic-colors-primary-teal-100);
      }

      p,
      li {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        color: var(--emu-semantic-colors-secondary-dark-gray-300);
        line-height: var(--emu-common-line-heights-wide-medium);
      }

      p {
        margin-bottom: var(--emu-common-spacing-medium);
        padding: var(--emu-common-spacing-none);

        a {
          color: var(--emu-semantic-colors-primary-teal-100);
        }

        a[href='#tel'] {
          color: var(--emu-semantic-colors-secondary-dark-gray-300);
          text-decoration: none;
        }
      }

      ul {
        margin-top: var(--emu-common-spacing-none);
        padding-left: 22px; // to match figma
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }

      h5 + p {
        margin-bottom: var(--emu-common-spacing-none);
      }

      .natrelle__isi {
        &-title {
          margin-bottom: var(--emu-common-spacing-large);
          margin-top: 1px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        &-text {
          margin-top: var(--emu-common-spacing-medium);

          p {
            margin-bottom: var(--emu-common-spacing-large);

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
        }
      }
    }
  }
}
